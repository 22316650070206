import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import useThemeStore from '../../store/themeStore';
import useTokenStore from '../../store/store';
import { ThemeProps } from '../../utils/theme';
import { useNavigate, useParams } from 'react-router-dom';

interface FloatingActionButtonsProps {
  themeColor: string;
  textColor: string;
  reserved: boolean;
}

const FloatingActionButtons: React.FC<FloatingActionButtonsProps> = ({ themeColor, textColor, reserved }) => {
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const { id: propertyId } = useParams(); // Get the property ID from the URL parameters
  const getToken = useTokenStore((state) => state.token);

  useEffect(() => {
    const handleScroll = () => {
      const originalButtons = document.getElementById('original-buttons');
      if (originalButtons) {
        const rect = originalButtons.getBoundingClientRect();
        setIsVisible(rect.bottom < 0);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const getThemes = useThemeStore((state) => state.theme as unknown as ThemeProps);

  const openModal = (modalId: string) => {
    if (!reserved) {
      const modal = document.getElementById(modalId);
      if (modal) {
        const hsOverlay = (window as any).HSOverlay;
        if (hsOverlay && typeof hsOverlay.open === 'function') {
          hsOverlay.open(modal);
        } else {
          console.error('HSOverlay not found or open method not available');
        }
      }
    }
  };

  const isWhiteOrShade = (color: string) => {
    if (!color) return false;
    if (color.toLowerCase() === "white" || color === "rgba(255,255,255,1)") return true;
    if (/^#fff[a-f0-9]{0,3}$/i.test(color)) return true;

    const rgba = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*([\d.]+)?\)/);
    if (rgba) {
      const [, r, g, b] = rgba.map(Number);
      return r >= 240 && g >= 240 && b >= 240;
    }

    return false;
  };

  const iconColor = isWhiteOrShade(themeColor) ? 'white' : textColor;

  if (!isVisible) return null;

  // Reserve Buttons 
  const themesWithoutReserveButton = ['properties.mypropertylisting.co.uk', 'Test Site 1', 'Test Site 2'];

  return (
    <div className="fixed bottom-28 right-8 flex flex-col space-y-4 z-[9999]">
      {/* Make Offer Button */}
      <div className="relative group">
        <div
          className={`absolute inset-0 rounded-full ${!reserved ? 'animate-ping' : ''}`}
          style={{
            backgroundColor: reserved ? 'grey' : themeColor,
            opacity: 0.2,
            animationDuration: '3s',
          }}
        />
        <button
          onClick={() => {
            if (!getToken) {
              // Redirect to login if not authenticated
              navigate(`/login?redirectTo=/listing/${propertyId}`);
            } else if (!reserved) {
              openModal('hs-slide-down-animation-modal1');
              console.log('Making an offer');
            }
          }}
          className={`relative w-14 h-14 rounded-full flex items-center justify-center shadow-lg ${
            reserved ? 'cursor-not-allowed' : 'hover:scale-110 transition duration-300'
          }`}
          style={{
            backgroundColor: reserved ? 'grey' : themeColor,
            color: reserved ? '#ccc' : iconColor,
            cursor: reserved ? 'not-allowed' : 'pointer',
          }}
          disabled={reserved}
        >
          <FontAwesomeIcon icon={faHandshake} size="lg" />
        </button>
        <div
          className="absolute right-full mr-2 top-1/2 transform -translate-y-1/2 px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap"
          style={{ backgroundColor: reserved ? 'grey' : themeColor, color: reserved ? '#ccc' : iconColor }}
        >
          {reserved ? 'Reserved' : 'Make Offer'}
        </div>
      </div>

      {/* Reserve Button */}
      {!themesWithoutReserveButton.includes(getThemes.name) && (
        <div className="relative group">
          <div
            className={`absolute inset-0 rounded-full ${!reserved ? 'animate-ping' : ''}`}
            style={{
              backgroundColor: reserved ? 'grey' : themeColor,
              opacity: 0.2,
              animationDuration: '3s',
            }}
          />
          <button
            onClick={() => {
              if (!getToken) {
                // Redirect to login if not authenticated
                navigate(`/login?redirectTo=/listing/${propertyId}`);
              } else if (!reserved) {
                openModal('hs-slide-down-animation-modal');
                console.log('Reserving property');
              }
            }}
            className={`relative w-14 h-14 rounded-full flex items-center justify-center shadow-lg ${
              reserved ? 'cursor-not-allowed' : 'hover:scale-110 transition duration-300'
            }`}
            style={{
              backgroundColor: reserved ? 'grey' : themeColor,
              color: reserved ? '#ccc' : iconColor,
              cursor: reserved ? 'not-allowed' : 'pointer',
            }}
            disabled={reserved}
          >
            <FontAwesomeIcon icon={faShoppingCart} size="lg" />
          </button>
          <div
            className="absolute right-full mr-2 top-1/2 transform -translate-y-1/2 px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap"
            style={{ backgroundColor: reserved ? 'grey' : themeColor, color: reserved ? '#ccc' : iconColor }}
          >
            {reserved ? 'Reserved' : 'Reserve'}
          </div>
        </div>
      )}
    </div>
  );
};

export default FloatingActionButtons;
